import { motion, useAnimation, useInView } from "framer-motion";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWindowResize } from "../../hooks/useWindowResize";
import R from "../../res";
import {
  backgroundFadeInVariants,
  blinkVariants,
  fadeInUpVariants,
  fadeInVariants,
  scaleInUpVariants,
  viewVariants,
} from "./constants";
import classes from "./index.module.scss";

const HomePage = () => {
  const [showRequestAQuoteModal, setShowRequestAQuoteModal] =
    React.useState(false);

  const { isSmallerDevice } = useWindowResize();

  const navigate = useNavigate();
  const viewAnimation = useAnimation();
  const blinkAnimation = useAnimation();

  const techSupportAnimation = useAnimation();
  const techSupportRef = useRef<HTMLDivElement>(null);
  const techSupportInView = useInView(techSupportRef, {
    once: true,
    amount: 1,
  });

  const ultimateSolutionAnimation = useAnimation();
  const ultimateSolutionRef = useRef<HTMLDivElement>(null);
  const ultimateSolutionInView = useInView(ultimateSolutionRef, {
    once: true,
    amount: 0.2,
  });

  const servicesAnimation = useAnimation();
  const servicesRef = useRef<HTMLDivElement>(null);
  const servicesInView = useInView(servicesRef, {
    once: true,
    amount: 0.2,
  });

  const footerAnimation = useAnimation();
  const footerRef = useRef<HTMLDivElement>(null);
  const footerInView = useInView(footerRef, {
    once: true,
    amount: 0.5,
  });

  React.useEffect(() => {
    if (techSupportInView) {
      techSupportAnimation.start("fadeIn");
    } else {
      techSupportAnimation.start("fadeOut");
    }
  }, [techSupportInView]);

  React.useEffect(() => {
    if (ultimateSolutionInView) {
      ultimateSolutionAnimation.start("fadeIn");
    } else {
      ultimateSolutionAnimation.start("fadeOut");
    }
  }, [ultimateSolutionInView]);

  React.useEffect(() => {
    if (servicesInView) {
      servicesAnimation.start("fadeIn");
    } else {
      servicesAnimation.start("fadeOut");
    }
  }, [servicesInView]);

  React.useEffect(() => {
    if (footerInView) {
      footerAnimation.start("fadeIn");
    } else {
      footerAnimation.start("fadeOut");
    }
  }, [footerInView]);

  const blinkingAnimation = async () => {
    const animation = async () => {
      await blinkAnimation.start("show");
      return await blinkAnimation.start("hide");
    };

    let repeatCount = 0;
    while (repeatCount < 10000) await animation();
  };

  React.useEffect(() => {
    blinkingAnimation();
    return () => blinkAnimation.stop();
  }, []);

  const services = React.useMemo(
    () => [
      {
        icon: R.images.default.customSoftwareDevelopmentIcon,
        title: `Custom Software\nDevelopment`,
        description:
          "We offer a wide range of solutions ranging from small touch ups, remodels, all the way to full business software solutions.",
        to: "/services/custom-software-development",
      },
      {
        icon: R.images.default.mobileAppDevelopmentIcon,
        title: `Mobile App\nDevelopment`,
        description:
          "EN2 has the Mobile App developers you need. Professional up to date Developers and Designers can get your app to market quickly and beautifully.",
        to: "/services/mobile-app-development",
      },
      {
        icon: R.images.default.cloudAdministrationIcon,
        title: `Cloud\nAdministration`,
        description:
          "Our Certified AWS Engineers can handle your Cloud Service needs, reducing the cost of in-house specialists. We can integrate with your workforce or handle everything your business needs.",
        to: "/services/cloud-administration",
      },
      {
        icon: R.images.default.awsCertifiedIcon,
        title: `AWS\nCertified`,
        description:
          "Our Certified Amazon Web Services Cloud Architects can craft the right hosting and virtual environments to fit your business needs.",
        to: "/services/aws-certified",
      },
      {
        icon: R.images.default.managedServiceProviderIcon,
        title: `Managed Service\nProvider`,
        description:
          "We cater to Small Businesses with our affordable Managed Services for your IT needs. We have the right tools to Monitor your network, workstations, and servers for a low cost.",
        to: "/services/managed-service-provider",
      },
      {
        icon: R.images.default.onSiteServiceIcon,
        title: `On-Site\nService`,
        description:
          "EN2 Tech offers On-Site Technical Consulting, Estimates and Repairs in the Middle Tennessee Area.",
        to: "/services/on-site-service",
      },
    ],
    [],
  );

  const toggleModal = React.useCallback(() => {
    if (showRequestAQuoteModal) {
      viewAnimation.start("scaleDown");
    } else {
      viewAnimation.start("scaleUp");
    }
    setShowRequestAQuoteModal((prevValue) => !prevValue);
  }, [setShowRequestAQuoteModal, showRequestAQuoteModal]);

  return (
    <>
      {/* <RequestAQuoteModal
        isOpen={showRequestAQuoteModal}
        onClose={toggleModal}
      /> */}
      <motion.div
        className={classes["container"]}
        animate={viewAnimation}
        // initial="scaleDown"
        // animate="scaleDown"
        // exit="scaleUp"
        variants={viewVariants}
      >
        <motion.img
          src={R.images.default.modalBackground}
          alt="background"
          className={classes["background-shape"]}
          initial="fadeOut"
          animate="fadeIn"
          custom={0.6}
          variants={fadeInVariants}
        />
        <div className={classes["content"]}>
          <>
            <motion.img
              src={R.images.default.gradient1}
              alt="gradient1"
              className={classes["gradient-1"]}
              animate="fadeIn"
              initial="fadeOut"
              custom={0.4}
              variants={fadeInVariants}
            />
            <motion.img
              src={R.images.default.shape1}
              alt="shape1"
              className={classes["shape-1"]}
              animate={footerAnimation}
              custom={0.6}
              variants={fadeInVariants}
            />
            <motion.img
              src={R.images.default.shape2}
              alt="shape2"
              className={classes["shape-2"]}
              animate={ultimateSolutionAnimation}
              custom={0.4}
              variants={fadeInVariants}
            />
            <motion.img
              src={R.images.default.shape3}
              alt="shape3"
              className={classes["shape-3"]}
              animate="fadeIn"
              initial="fadeOut"
              custom={0.4}
              variants={fadeInVariants}
            />
            <motion.img
              src={R.images.default.shape4}
              alt="shape4"
              className={classes["shape-4"]}
              animate="fadeIn"
              initial="fadeOut"
              custom={0.4}
              variants={fadeInVariants}
            />
            <motion.img
              src={R.images.default.gradient2}
              alt="gradient4"
              className={classes["gradient-4"]}
              animate={techSupportAnimation}
              variants={scaleInUpVariants}
              custom={0.3}
            />
            <motion.img
              src={R.images.default.gradient2}
              alt="gradient5"
              className={classes["gradient-5"]}
              animate="fadeIn"
              initial="fadeOut"
              custom={0.4}
              variants={scaleInUpVariants}
            />
            <motion.img
              src={R.images.default.gradient2}
              alt="gradient6"
              className={classes["gradient-6"]}
              animate="fadeIn"
              initial="fadeOut"
              custom={0.4}
              variants={scaleInUpVariants}
            />
            <motion.img
              src={R.images.default.gradientDiamond}
              alt="gradientDiamond1"
              className={classes["gradient-diamond-1"]}
              variants={scaleInUpVariants}
              animate={techSupportAnimation}
              // custom={1}
            />
            <motion.img
              src={R.images.default.gradientDiamond}
              alt="gradientDiamond2"
              className={classes["gradient-diamond-2"]}
              variants={scaleInUpVariants}
              animate={techSupportAnimation}
              // custom={1}
            />
            <motion.img
              src={R.images.default.gradientDiamond}
              alt="gradientDiamond3"
              className={classes["gradient-diamond-3"]}
              variants={scaleInUpVariants}
              animate={techSupportAnimation}
              // custom={1}
            />
            <motion.img
              src={R.images.default.gradientDiamond}
              alt="gradientDiamond4"
              className={classes["gradient-diamond-4"]}
              variants={scaleInUpVariants}
              animate={techSupportAnimation}
              // custom={1}
            />
            <motion.button
              animate="fadeIn"
              initial="fadeOut"
              custom={0.4}
              variants={scaleInUpVariants}
              className={classes["gradient-button-1"]}
            >
              <img src={R.images.default.icPaperPlane} alt="send message" />
            </motion.button>
            <motion.button
              animate="fadeIn"
              initial="fadeOut"
              custom={0.4}
              variants={scaleInUpVariants}
              className={classes["gradient-button-2"]}
            >
              <img src={R.images.default.icDatabase} alt="database" />
            </motion.button>
            <motion.button
              animate="fadeIn"
              initial="fadeOut"
              custom={0.4}
              variants={scaleInUpVariants}
              className={classes["gradient-button-3"]}
            >
              <img
                src={R.images.default.customSoftwareDevelopmentIcon}
                alt="custom software"
              />
            </motion.button>
            <motion.button
              animate="fadeIn"
              initial="fadeOut"
              custom={0.4}
              variants={scaleInUpVariants}
              className={classes["gradient-button-4"]}
            >
              <img src={R.images.default.icPen} alt="pencil" />
            </motion.button>

            <motion.div
              animate={blinkAnimation}
              custom={0.6}
              variants={blinkVariants}
              className={classes["dot-1"]}
            />
            <motion.div
              animate={blinkAnimation}
              custom={0.65}
              variants={blinkVariants}
              className={classes["dot-2"]}
            />
            <motion.div
              animate={blinkAnimation}
              custom={0.7}
              variants={blinkVariants}
              className={classes["dot-3"]}
            />
            <motion.div
              animate={blinkAnimation}
              custom={0.75}
              variants={blinkVariants}
              className={classes["dot-4"]}
            />
            <motion.div
              animate={blinkAnimation}
              custom={0.8}
              variants={blinkVariants}
              className={classes["dot-5"]}
            />
            <motion.div
              animate={blinkAnimation}
              custom={0.85}
              variants={blinkVariants}
              className={classes["dot-6"]}
            />
            <motion.div
              animate={blinkAnimation}
              custom={0.9}
              variants={blinkVariants}
              className={classes["dot-7"]}
            />
            <motion.div
              animate={blinkAnimation}
              custom={0.95}
              variants={blinkVariants}
              className={classes["dot-8"]}
            />
            <motion.div
              animate={blinkAnimation}
              custom={1}
              variants={blinkVariants}
              className={classes["dot-9"]}
            />
            <motion.div
              animate={blinkAnimation}
              custom={1.05}
              variants={blinkVariants}
              className={classes["dot-10"]}
            />
            <motion.div
              animate={blinkAnimation}
              custom={1.1}
              variants={blinkVariants}
              className={classes["dot-11"]}
            />
          </>

          <div
            className={classes["mask"]}
            style={{ marginTop: isSmallerDevice ? "4rem" : "5rem" }}
          >
            <motion.img
              src={R.images.default.icLogo}
              alt="logo"
              animate="fadeIn"
              initial="fadeOut"
              variants={fadeInUpVariants}
              className={classes["logo"]}
              custom={0.2}
            />
          </div>
          <div className={classes["shape-3"]} />

          <div
            className={classes["mask"]}
            style={{
              margin: "4rem 0 2rem 0",
            }}
          >
            <motion.p
              className={classes["main-title"]}
              animate="fadeIn"
              initial="fadeOut"
              variants={fadeInUpVariants}
              custom={0.2}
            >
              <span className={classes["gradient"]}>
                25 years of experience{" "}
              </span>
              <span className={classes["white"]}>
                working with clients in the construction, logistics and
                hospitality sphere
              </span>
            </motion.p>
          </div>
          {/* <div className={classes["mask"]}>
            <motion.p
              className={classes["main-label"]}
              animate="fadeIn"
              initial="fadeOut"
              variants={fadeInUpVariants}
              custom={0.35}
            >
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form
            </motion.p>
          </div> */}
          <div className={classes["mask"]}>
            <motion.button
              className={`${classes["button"]} ${classes["request-quote"]}`}
              onClick={() => {
                // toggleModal
                viewAnimation.start("scaleUp");
                setTimeout(() => {
                  navigate("/contact");
                }, 500);
              }}
              animate="fadeIn"
              initial="fadeOut"
              variants={fadeInUpVariants}
              custom={0.5}
            >
              <p>Request a quote</p>
            </motion.button>
          </div>
          <motion.p
            initial="fadeOut"
            animate="fadeIn"
            custom={0.6}
            variants={fadeInVariants}
            className={classes["two"]}
          >
            2
          </motion.p>
          <motion.p
            initial="fadeOut"
            animate="fadeIn"
            custom={0.6}
            variants={fadeInVariants}
            className={classes["n"]}
          >
            n
          </motion.p>
          <motion.div
            animate={ultimateSolutionAnimation}
            custom={0.6}
            variants={fadeInVariants}
            className={classes["n-two"]}
          >
            <p>n2</p>
          </motion.div>
          <motion.div
            initial="fadeOut"
            animate="fadeIn"
            custom={0.6}
            variants={fadeInVariants}
            className={classes["image-1"]}
          >
            <img
              src={R.images.default.ilHomeHero}
              alt="image-1"
              className={classes["image"]}
            />
            <img
              src={R.images.default.bg1}
              alt="bg-1"
              className={classes["bg"]}
            />
            <img
              src={R.images.default.starCircle}
              alt="star-circle-1"
              className={classes["star-circle-1"]}
            />
            <img
              src={R.images.default.starCircle}
              alt="star-circle-2"
              className={classes["star-circle-2"]}
            />
            <img
              src={R.images.default.starCircle}
              alt="star-circle-3"
              className={classes["star-circle-3"]}
            />
            <img
              src={R.images.default.starCircle}
              alt="star-circle-4"
              className={classes["star-circle-4"]}
            />
            <div className={classes["gradient-circle-1"]} />
            <div className={classes["gradient-circle-2"]} />
            <div className={classes["gradient-circle-3"]} />
            <div className={classes["gradient-circle-4"]} />
          </motion.div>
          <div className={classes["tech-support"]} ref={techSupportRef}>
            <motion.img
              src={R.images.default.gradient2}
              alt="gradient2"
              className={classes["gradient-2"]}
              animate={techSupportAnimation}
              variants={scaleInUpVariants}
            />
            <div className={classes["left-container"]}>
              <motion.img
                src={R.images.default.techSupport}
                alt="tech-support-logo"
                className={classes["image"]}
                variants={scaleInUpVariants}
                animate={techSupportAnimation}
                custom={0.2}
              />
            </div>
            <div className={classes["section"]}>
              <div
                className={classes["mask"]}
                style={{
                  marginBottom: "0.25rem",
                }}
              >
                <motion.div
                  className={classes["top"]}
                  variants={fadeInUpVariants}
                  animate={techSupportAnimation}
                >
                  <div className={classes["line"]} />
                  <p>EN2 Tech Support</p>
                </motion.div>
              </div>
              <div className={classes["mask"]}>
                <motion.p
                  className={classes["title"]}
                  variants={fadeInUpVariants}
                  animate={techSupportAnimation}
                  custom={0.2}
                >
                  Each <span>Member</span> of your team has a job to do
                </motion.p>
              </div>
              <div
                className={classes["mask"]}
                style={{
                  marginTop: "1rem",
                }}
              >
                <motion.p
                  className={classes["description"]}
                  variants={fadeInUpVariants}
                  animate={techSupportAnimation}
                  custom={0.3}
                >
                  Let EN2 Tech support your team with the right tools for the
                  job. Whether it be Custom Web Applications to streamline and
                  automate tasks in your day to day, or to increase productivity
                  and lower your carbon footprint by migrating to the Cloud -{" "}
                  <span>EN2 Can do it all.</span>
                </motion.p>
              </div>
              {/* <div
                className={classes["mask"]}
                style={{
                  marginTop: "2rem",
                }}
              >
                <motion.button
                  className={`${classes["button"]}`}
                  variants={fadeInUpVariants}
                  animate={techSupportAnimation}
                  custom={0.4}
                >
                  <p>Let's get started</p>
                </motion.button>
              </div> */}
            </div>
          </div>
          <div
            className={classes["ultimate-solution"]}
            ref={ultimateSolutionRef}
          >
            <motion.img
              src={R.images.default.gradient2}
              alt="gradient3"
              className={classes["gradient-3"]}
              animate={ultimateSolutionAnimation}
              variants={scaleInUpVariants}
              custom={0.3}
            />
            <div className={classes["section"]}>
              <div
                className={classes["mask"]}
                style={{
                  marginBottom: "0.25rem",
                }}
              >
                <motion.div
                  className={classes["top"]}
                  variants={fadeInUpVariants}
                  animate={ultimateSolutionAnimation}
                >
                  <div className={classes["line"]} />
                  <p>Ultimate Solution</p>
                </motion.div>
              </div>
              <div className={classes["mask"]}>
                <motion.p
                  className={classes["title"]}
                  variants={fadeInUpVariants}
                  animate={ultimateSolutionAnimation}
                  custom={0.2}
                >
                  <span>Grow</span> your business in every aspect in the right
                  direction for you.
                </motion.p>
              </div>
              <div
                className={classes["mask"]}
                style={{
                  marginTop: "1rem",
                }}
              >
                <motion.p
                  className={classes["description"]}
                  variants={fadeInUpVariants}
                  animate={ultimateSolutionAnimation}
                  custom={0.3}
                >
                  We offer consulting services from remote and phone consults to
                  full in person, on-premises reviews of your business
                  operations to give you the best advice and solutions for your
                  business.
                </motion.p>
              </div>
              {/* <div
                className={classes["mask"]}
                style={{
                  marginTop: "2rem",
                }}
              >
                <motion.button
                  className={`${classes["button"]}`}
                  variants={fadeInUpVariants}
                  animate={ultimateSolutionAnimation}
                  custom={0.4}
                >
                  <p>Let's get started</p>
                </motion.button>
              </div> */}
            </div>
            {services.slice(0, 2).map((service, index) => {
              return (
                <div className={classes["item"]} key={index}>
                  <div className={classes["mask"]}>
                    <motion.div
                      className={classes["image-wrapper"]}
                      animate={ultimateSolutionAnimation}
                      variants={scaleInUpVariants}
                      custom={0.4}
                    >
                      <img
                        src={R.images.default.shape}
                        alt="shape"
                        className={classes["image"]}
                      />
                      <img
                        src={service.icon}
                        alt={service.title}
                        className={classes["center-icon"]}
                      />
                    </motion.div>
                  </div>
                  <div
                    className={classes["mask"]}
                    style={{ marginTop: "0.75rem" }}
                  >
                    <motion.p
                      variants={fadeInUpVariants}
                      animate={ultimateSolutionAnimation}
                      className={classes["title"]}
                      custom={0.4}
                    >
                      {service.title}
                    </motion.p>
                  </div>
                  <div
                    className={classes["mask"]}
                    style={{ margin: "0.75rem 0" }}
                  >
                    <motion.p
                      variants={fadeInUpVariants}
                      animate={ultimateSolutionAnimation}
                      className={classes["label"]}
                      custom={0.4}
                    >
                      {service.description}
                    </motion.p>
                  </div>
                  {/* <div
                    className={classes["mask"]}
                    style={{ marginTop: "0.5rem" }}
                  >
                    <Link to={service.to}>
                      <motion.button
                        variants={fadeInUpVariants}
                        animate={ultimateSolutionAnimation}
                        className={`${classes["button"]}`}
                        custom={0.4 + index * 0.15}
                      >
                        <p>Read More</p>
                      </motion.button>
                    </Link>
                  </div> */}
                </div>
              );
            })}
          </div>
          <div className={classes["services"]} ref={servicesRef}>
            {services.slice(2).map((service, index) => {
              return (
                <div className={classes["item"]} key={index}>
                  <div className={classes["mask"]}>
                    <motion.div
                      className={classes["image-wrapper"]}
                      animate={servicesAnimation}
                      variants={scaleInUpVariants}
                      custom={0.4 + index * 0.15}
                    >
                      <img
                        src={R.images.default.shape}
                        alt="shape"
                        className={classes["image"]}
                      />
                      <img
                        src={service.icon}
                        alt={service.title}
                        className={classes["center-icon"]}
                      />
                    </motion.div>
                  </div>
                  <div
                    className={classes["mask"]}
                    style={{ marginTop: "0.75rem" }}
                  >
                    <motion.p
                      variants={fadeInUpVariants}
                      animate={servicesAnimation}
                      className={classes["title"]}
                      custom={0.4 + index * 0.15}
                    >
                      {service.title}
                    </motion.p>
                  </div>
                  <div
                    className={classes["mask"]}
                    style={{ margin: "0.75rem 0" }}
                  >
                    <motion.p
                      variants={fadeInUpVariants}
                      animate={servicesAnimation}
                      className={classes["label"]}
                      custom={0.4 + index * 0.15}
                    >
                      {service.description}
                    </motion.p>
                  </div>
                  {/* <div
                    className={classes["mask"]}
                    style={{ marginTop: "0.5rem" }}
                  >
                    <Link to={service.to}>
                      <motion.button
                        variants={fadeInUpVariants}
                        animate={servicesAnimation}
                        className={`${classes["button"]}`}
                        custom={0.4 + index * 0.15}
                      >
                        <p>Read More</p>
                      </motion.button>
                    </Link>
                  </div> */}
                </div>
              );
            })}
          </div>
          <motion.img
            src={R.images.default.map}
            alt="map"
            className={classes["map"]}
            animate={footerAnimation}
            variants={fadeInVariants}
            custom={0.5}
          />

          <motion.p
            className={classes["en"]}
            animate={footerAnimation}
            variants={fadeInVariants}
            custom={0.5}
          >
            n
          </motion.p>
          <div className={classes["footer"]} ref={footerRef}>
            <motion.div
              className={classes["background"]}
              animate={footerAnimation}
              variants={backgroundFadeInVariants}
              custom={0.4}
            />
            <div className={classes["footer-content"]}>
              <div
                className={classes["mask"]}
                style={{
                  paddingTop: "1rem",
                }}
              >
                <motion.div
                  animate={footerAnimation}
                  variants={fadeInUpVariants}
                  custom={0.4}
                  className={classes["contact-container"]}
                >
                  <img
                    src={R.images.default.contactContainer}
                    alt="bg-image"
                    className={classes["bg-image"]}
                  />
                  <div className={classes["contact"]}>
                    <div className={classes["row"]}>
                      <img
                        src={R.images.default.icLocation}
                        alt="location"
                        className={classes["icon"]}
                      />
                      <p>Murfreesboro, TN</p>
                    </div>
                    <div className={classes["row"]}>
                      <img
                        src={R.images.default.icPhone}
                        alt="location"
                        className={classes["icon"]}
                      />
                      {/* <p>+1 615-208-6228</p> */}
                      <p>+1 615-767-1689</p>
                    </div>
                    <div className={classes["row"]}>
                      <img
                        src={R.images.default.icMail}
                        alt="location"
                        className={classes["icon"]}
                      />
                      <p>support@en2.tech</p>
                    </div>
                  </div>
                </motion.div>
              </div>
              <div
                className={classes["mask"]}
                style={{ marginTop: "-0.75rem" }}
              >
                <motion.img
                  src={R.images.default.icDiamond}
                  alt="diamond"
                  className={classes["diamond"]}
                  animate={footerAnimation}
                  variants={fadeInUpVariants}
                  custom={0.5}
                />
              </div>
              <div className={classes["mask"]} style={{ marginTop: "0.75rem" }}>
                <motion.img
                  src={R.images.default.icLogo}
                  alt="logo"
                  className={classes["en2-logo"]}
                  animate={footerAnimation}
                  variants={fadeInUpVariants}
                  custom={0.5}
                />
              </div>
            </div>
            <div className={classes["bottom"]}>
              <div className={classes["row"]}>
                <div className={classes["mask"]}>
                  <motion.div
                    animate={footerAnimation}
                    variants={fadeInUpVariants}
                    custom={0.1}
                  >
                    <Link to="/terms-of-service">
                      <p>terms of service</p>
                    </Link>
                  </motion.div>
                </div>
                {/* <div className={classes["mask"]}>
                  <motion.div
                    animate={footerAnimation}
                    variants={fadeInUpVariants}
                    custom={0.2}
                  >
                    <Link to="/privacy-policy">
                      <p>privacy policy</p>
                    </Link>
                  </motion.div>
                </div> */}
              </div>
              <div className={classes["mask"]}>
                <a href="https://cyboticx.com" target="_blank" rel="dofollow">
                  <motion.img
                    src={R.images.default.madeWithLove}
                    alt="made-with-love"
                    className={classes["made-with-love"]}
                    animate={footerAnimation}
                    variants={fadeInUpVariants}
                    custom={0.3}
                  />
                </a>
              </div>
              <div className={classes["mask"]}>
                <motion.p
                  animate={footerAnimation}
                  variants={fadeInUpVariants}
                  custom={0.4}
                >
                  Copyright © 2022 Cyboticx LLC
                </motion.p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default HomePage;

import axios from "axios";

const sendMail = async (
  fullname: string,
  services: Array<string>,
  heardFrom: string,
  email: string,
  phone: string,
  budget: string,
  projectBrief: string,
) => {
  const form = new FormData();
  const domain = "mg.en2.tech";

  try {
    form.append("from", `noreply@${domain}`);
    // form.append("to", process.env.REACT_APP_CONTACT_FORM_EMAIL!)
    form.append("to", "john@en2.tech");
    form.append("subject", "EN2 Contact Form");
    form.append(
      "text",
      `Full name: ${fullname}\nEmail: ${email}\nPhone: ${phone}\nHeard about EN2 from: ${heardFrom}\nProject Brief: ${projectBrief}\nServices: ${services.join(
        ", ",
      )}\nBudget: ${budget}`,
    );

    const endpoint = `https://api.mailgun.net/v3/${domain}/messages`;

    await axios.post(endpoint, form, {
      auth: {
        username: "api",
        password: process.env.REACT_APP_MAILGUN_API_KEY!,
      },
      headers: { "Content-Type": "multipart/form-data" },
    });
    return true;
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export default sendMail;

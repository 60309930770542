
    
    
    import awsCertifiedIcon from './images/aws_certified_icon.png';
import bg1 from './images/bg_1.png';
import cloudAdministrationIcon from './images/cloud_administration_icon.png';
import contactContainer from './images/contact_container.png';
import customSoftwareDevelopmentIcon from './images/custom_software_development_icon.png';
import enShape from './images/en_shape.png';
import gradient1 from './images/gradient_1.png';
import gradient2 from './images/gradient_2.png';
import gradientDiamond from './images/gradient_diamond.png';
import icPhone from './images/ic-phone.png';
import icChevron from './images/ic_chevron.png';
import icDatabase from './images/ic_database.png';
import icDiamond from './images/ic_diamond.png';
import icLocation from './images/ic_location.png';
import icLogo from './images/ic_logo.png';
import icMail from './images/ic_mail.png';
import icPaperPlane from './images/ic_paper_plane.png';
import icPen from './images/ic_pen.png';
import ilHomeHero from './images/il_home_hero.jpg';
import image1 from './images/image_1.png';
import madeWithLove from './images/made_with_love.png';
import managedServiceProviderIcon from './images/managed_service_provider_icon.png';
import map from './images/map.png';
import mobileAppDevelopmentIcon from './images/mobile_app_development_icon.png';
import modalBackIcon from './images/modal_back_icon.png';
import modalBackground from './images/modal_background.png';
import n2Shape from './images/n2_shape.png';
import onSiteServiceIcon from './images/on_site_service_icon.png';
import shape from './images/shape.png';
import shape1 from './images/shape_1.png';
import shape2 from './images/shape_2.png';
import shape3 from './images/shape_3.png';
import shape4 from './images/shape_4.png';
import starCircle from './images/star_circle.png';
import techSupport from './images/tech_support.png';

		const images = {
      dark: {},
      light: {},
      default: {awsCertifiedIcon,bg1,cloudAdministrationIcon,contactContainer,customSoftwareDevelopmentIcon,enShape,gradient1,gradient2,gradientDiamond,icPhone,icChevron,icDatabase,icDiamond,icLocation,icLogo,icMail,icPaperPlane,icPen,ilHomeHero,image1,madeWithLove,managedServiceProviderIcon,map,mobileAppDevelopmentIcon,modalBackIcon,modalBackground,n2Shape,onSiteServiceIcon,shape,shape1,shape2,shape3,shape4,starCircle,techSupport},
    };

    export default images;
  